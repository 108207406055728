<template>
    <div>
        <div class="main-content">
            <div class="content">
                <div class="tab">
                    <el-button type="primary" @click="goBack">返回</el-button>
                </div>
                <el-table :data="infoList" class="customTable" style="width: 100%; margin-top: 20px" height="1%">
                    <el-table-column prop="goods_name" label="封面主图" align="center">
                        <template slot-scope="scope">
                            <img class="goods-img" :src="scope.row.cover_url" alt="">
                        </template>
                    </el-table-column>
                    <el-table-column prop="title" label="标题" align="center"></el-table-column>
                    <el-table-column prop="created_at" label="发布时间" align="center"></el-table-column>
                    <el-table-column prop="browse_num" label="浏览次数" align="center"></el-table-column>
                    <el-table-column prop="delete_note" label="删除原因" align="center">
                        <template slot-scope="scope">
                            <div v-html="scope.row.delete_note"></div>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" align="center">
                        <template slot-scope="scope">
                            <el-link type="success" :underline="false" @click="recycleBtn(scope.row)">恢复</el-link>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination class="pages-center"
                               :current-page="listPages.currentPageNum"
                               :page-size="listPages.eachPageNum"
                               layout="prev, pager, next, jumper"
                               :total="listPages.total"
                               @current-change="pageCurrentChange">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    import {talk_recycleBin, talk_restoreTalk} from '@/config/apis'

    export default {
        data() {
            return {
                infoList: [],
                listPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
            }
        },
        mounted() {
            this.getList()
        },
        methods: {
            getList() {
                let params = {
                    paging: '1',
                    pageSize: this.listPages.eachPageNum,
                    page: this.listPages.currentPageNum,
                }
                talk_recycleBin(params).then((res) => {
                    this.infoList = res.data.list
                    this.listPages.total = res.data.total
                    if (this.listPages.total !== 0 && this.infoList.length === 0) {
                        this.listPages.currentPageNum--;
                        this.getList();
                    }
                })
            },
            // 切换页面
            pageCurrentChange(val) {
                this.listPages.currentPageNum = val
                this.getList()
            },
            recycleBtn(row) {
                this.$confirm('确定恢复？', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                }).then(() => {
                    talk_restoreTalk({courier_talk_id: row.courier_talk_id}).then((res) => {
                        this.$message.success(res.msg)
                        this.getList()
                    })
                }).catch(() => {
                    this.$message.info('已取消删除');
                })
            },
            goBack() {
                this.$router.go(-1)
            }
        }
    }
</script>

<style scoped lang="scss">
    .main-content {
        margin: 20px;
        background: #fff;
        height: calc(100% - 40px);
        border: 1px solid #e6e6e6;
        display: flex;
        flex-direction: column;
    }

    .tab {
        text-align: right;
    }

    .content {
        flex: 1;
        height: 1%;
        padding: 20px;
        display: flex;
        flex-direction: column;
    }

    .goods-img {
        max-width: 200px;
        height: 100px;
    }
</style>